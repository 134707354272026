function DhmeModuleDashboardExtension(viewer, options) {
  Autodesk.Viewing.Extension.call(this, viewer, options);
  this.viewer = viewer;
  this.vueInstance = options.vueInstance;
}

DhmeModuleDashboardExtension.prototype = Object.create(
  Autodesk.Viewing.Extension.prototype
);
DhmeModuleDashboardExtension.prototype.constructor =
  DhmeModuleDashboardExtension;

DhmeModuleDashboardExtension.prototype.load = function () {
  console.log('DhmeModuleDashboardExtension is loaded!');
  return true;
};

DhmeModuleDashboardExtension.prototype.unload = function () {
  console.log('DhmeModuleDashboardExtension is now unloaded!');
  return true;
};

DhmeModuleDashboardExtension.prototype.colorModuleTypes = function (
  moduleTypes
) {
  let viewer = this.viewer;
  viewer.clearThemingColors();

  moduleTypes.forEach((module) => {
    let objects = this.options.properties.filter(
      (prop) => prop.properties.JanSnel?.ModuleType === module.type
    );

    let color = hexToVector4(module.color);
    objects
      .map((x) => x.objectid)
      .forEach((id) => {
        viewer.setThemingColor(id, color, null, true);
      });
  });
};

DhmeModuleDashboardExtension.prototype.setMDPhaseColors = function (
  modules,
  phases
) {
  let viewer = this.viewer;
  viewer.clearThemingColors();

  phases.forEach((p) => {
    let modulesInPhase = modules.filter((m) => m.phase === p.name);
    let allObjects = [];
    modulesInPhase.forEach((x) => {
      if (x !== '') {
        let objects = this.options.properties.filter(
          (prop) => prop.properties?.JanSnel?.EquipmentNummer === x.module_id
        );
        allObjects = [...allObjects, ...objects];
      }
    });
    allObjects
      .map((x) => x.objectid)
      .forEach((id) => {
        let color = hexToVector4(p.color);
        viewer.setThemingColor(id, color, null, true);
      });
  });
};

function hexToVector4(hex) {
  let result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);
  let color = result
    ? {
        r: parseInt(result[1], 16),
        g: parseInt(result[2], 16),
        b: parseInt(result[3], 16),
      }
    : null;
  let theeColor = new THREE.Color(`rgb(${color.r}, ${color.g}, ${color.b})`);
  return new THREE.Vector4(theeColor.r, theeColor.g, theeColor.b, 1);
}

Autodesk.Viewing.theExtensionManager.registerExtension(
  'DhmeModuleDashboardExtension',
  DhmeModuleDashboardExtension
);
